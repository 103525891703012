<template>
  <div class="head-bar-container" @click.self="closeMenu">
    <div class="head-bar-row pc_padding">
      <div class="head-bar-name-row cursor" @click="gohome">
        <div class="head-bar-name-icon">
          <img src="/logo.png" alt="">
        </div>
        <div class="head-bar-name" style="font-family: Poppins;">
          {{ title }}
        </div>
      </div>
      <div class="head-bar-hamber-row cursor" @click="toggleOpenState">
        <img src="@/assets/menu.png" alt="">
      </div>
    </div>

    <div class="menu" :class="{ 'menu-open': isOpen }">
      <div class="menu-title">
        Resource
      </div>
      <ul>
        <li @click="goto('terms')">
          <a>Terms of services</a>
          <div class="arrow" />
        </li>
        <li @click="goto('privacy')">
          <a>Privacy policy</a>
          <div class="arrow" />
        </li>
        <li @click="goto('disclaimer')">
          <a>Disclaimer</a>
          <div class="arrow" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: false
    },

  },
  data () {
    return {
      isOpen: false,
    }
  },
  mounted () {
    document.onscroll = () => {
      this.isOpen = false
    }
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    gohome () {
      window.location.href = '/'
    },
    toggleOpenState () {
      this.isOpen = !this.isOpen;
    },
    handleClickOutside (event) {
      if (this.isOpen && !this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    },
    closeMenu () {
      if (this.isOpen) {
        this.isOpen = false;
      }
    },
    goto (where) {
      console.log(this.$route.path);
      this.isOpen = false;
      if (where == 'home') {
        this.$emit('setRequestDomainHome');
      }
      if (where == 'home' && this.$route.path == '/') {
        this.scrollToTop()
        return
      }
      this.$router.push({ name: where });
    },
  },
}
</script>
